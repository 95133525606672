import {
  borderRadius as radii,
  borderWidths,
  opacityLevels,
  shadowLevels,
  spacing,
  fontFamilies,
  fontSizes,
  fontWeights,
  fontLineHeights,
  zIndice,
  colors as webTokensColors
} from '@cash-me/web-tokens'

enum Color {
  blue = 'blue',
  cyan = 'cyan'
}

const setColorBase = (baseName: string, colorName: Color) =>
  Object.entries(webTokensColors[`${colorName}Colors`]).reduce(
    (colors, [tokenKey, tokenValue]) => ({
      ...colors,
      [tokenKey.replace(colorName, baseName)]: tokenValue
    }),
    {}
  )

export const theme = {
  colors: {
    ...setColorBase('primary', Color.blue),
    ...setColorBase('secondary', Color.cyan),
    ...webTokensColors.blueColors,
    ...webTokensColors.dangerColors,
    ...webTokensColors.cyanColors,
    ...webTokensColors.pinkColors,
    ...webTokensColors.neutralColors,
    ...webTokensColors.successColors,
    ...webTokensColors.warningColors
  },
  media: {
    phone: 'max-width: 767px',
    tablet: 'min-width: 768px',
    desktop: 'min-width: 1240px',
    hd: 'min-width: 1440px',
    motion: 'prefers-reduced-motion',
    hover: 'any-hover: hover',
    dark: 'prefers-color-scheme: dark',
    light: 'prefers-color-scheme: light'
  },
  space: { ...spacing },
  sizes: { ...spacing },
  fonts: { ...fontFamilies },
  fontSizes: { ...fontSizes },
  fontWeights: { ...fontWeights },
  lineHeights: { ...fontLineHeights },
  borderWidths: { ...borderWidths },
  radii: { ...radii },
  shadows: { ...shadowLevels },
  zIndices: { ...zIndice },
  opacity: { ...opacityLevels },
  transitions: {
    default: 'all 0.4s ease'
  }
}

export const darkTheme = {
  ...theme,
  colors: {
    ...webTokensColors.darkColors.blueColors,
    ...webTokensColors.darkColors.dangerColors,
    ...webTokensColors.darkColors.goldColors,
    ...webTokensColors.darkColors.neutralColors,
    ...webTokensColors.darkColors.successColors,
    ...webTokensColors.darkColors.warningColors
  }
}
