import { getSessionValue, setSessionValue } from '../session'

export const setTrackerStorage = () => {
  const origin = getSessionValue('origem')

  if (!origin) {
    const { pathname, search } = window.location

    setSessionValue('originPage', pathname)
    setSessionValue('campaignData', search)
    setSessionValue('origem', global?.document?.referrer ? 'organico' : 'direto')
  }
}
