import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    background: transparent;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    transition: transform 0.2s ease-out;
    font-family: inherit;
    font-size: inherit;
  }
  
  *::before,*::after {
    box-sizing: border-box;
  }

  html,
  body {
    min-width: 375px;
    font-size: 16px;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  ul, nav {
    list-style: none;
  }

  a {
    text-decoration: none;

    &.ds-link {
      position: relative;
      display: inline-flex;
      gap: var(--sizes-spacing-xxxs);
      color: var(--colors-primary-text-default);
      letter-spacing: 0.0125rem;
      
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0;
        border-bottom: var(--borderWidths-border-stroke-micro) solid var(--colors-primary-border-strong);
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
        transform: scaleX(0);
      }

      &:hover::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }

      &:focus::after: {
        transform: scaleX(1);
        transform-origin: bottom left;
      }

      &:active {
        color: var(--colors-neutral-text-default);

        &::after {
          transform: scaleX(1);
          transform-origin: bottom left;
          border-bottom-color: var(--colors-neutral-text-default);
        }
      }

      .ds-icon svg {
        width: 100%;
        height: 100%;
      }
    }
  }
`
