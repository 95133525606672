import { useEffect } from 'react'

import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { ThemeProvider } from 'styled-components'
import { register } from 'swiper/element/bundle'

import { NextHead } from 'modules/next-head'
import { setSessionValue } from 'utils/session'
import { setTrackerStorage } from 'utils/setTrackerStorage'

import { theme } from '../styles'

register()

function App({ Component, pageProps }: AppProps) {
  const router = useRouter()

  useEffect(() => {
    setTrackerStorage()
  })

  useEffect(() => {
    const handleRouteChange = () => {
      setSessionValue('prevPath', window.location.href)
    }

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router.events])

  return (
    <ThemeProvider theme={theme}>
      <NextHead {...pageProps.SEO} />
      <Component {...pageProps} />

      <Script id="axur">
        var i=new
        Image,u=&quot;https://s3-sa-east-1.amazonaws.com/frame-image-br/bg.png?x-id=5e85a65d58199ced701dda6cf11d9186&x-r=&quot;+document.referrer;i.src=u;
      </Script>
      <Script
        id="gtm-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');`
        }}
      />
    </ThemeProvider>
  )
}

export default App
