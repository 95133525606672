import { FC } from 'react'

import Head from 'next/head'
import { useRouter } from 'next/router'

import { HeadProps } from './head.model'

export const NextHead: FC<HeadProps> = ({
  title,
  description,
  titleOG = '',
  descriptionOG = '',
  canonical = '',
  robots = '',
  twitterImg = '',
  facebookImg = '',
  richSnippets = undefined
}) => {
  const { pathname } = useRouter()
  const site = 'https://www.cashme.com.br'
  const canURL = canonical || `${site}${pathname !== '/' ? pathname : ''}/`
  const fallbackImage = 'https://www.cashme.com.br/blog/wp-content/uploads/2023/01/og-pgs.jpg'
  const metaRobots = process.env.NEXT_PUBLIC_ENV === 'quality' ? 'noindex, nofollow' : robots

  return (
    <Head>
      <>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="revisit-after" content="7 days" />
        <meta name="copyright" content="© 2022 Cashme" />
        <meta name="rating" content="General" />
        <meta name="author" content="CashMe" />

        <meta name="geo.placename" content="São Paulo - SP" />
        <meta name="geo.region" content="SP-BR" />

        <meta name="format-detection" content="telephone=no" />

        {/* Icons */}
        <link rel="apple-touch-icon" sizes="180x180" href="/seo/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/seo/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="192x192" href="/seo/android-chrome-192x192.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/seo/favicon-16x16.png" />
        <link rel="shortcut icon" type="image/png" sizes="32x32" href="/seo/favicon-32x32.png" />
        <link rel="manifest" href="/seo/site.webmanifest" />
        <link rel="mask-icon" href="/seo/safari-pinned-tab.svg" color="#00bffe" />
        <meta name="apple-mobile-web-app-title" content="Cashme" />
        <meta name="application-name" content="Cashme" />
        <meta name="msapplication-TileColor" content="#00aba9" />
        <meta name="msapplication-TileImage" content="/seo/mstile-144x144.png" />
        <meta name="theme-color" content="#ffffff" />

        {/* Facebook */}
        <meta property="og:title" content={titleOG || title} />
        <meta property="og:description" content={descriptionOG || description} />
        <meta property="og:image" content={facebookImg || fallbackImage} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:url" content={canURL} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="pt_BR" />
        <meta property="og:site_name" content="CashMe" />
        <meta property="og:region" content="Brasil" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        {/* size image = 1200x1200 */}
        <meta name="twitter:image" content={twitterImg || fallbackImage} />

        <title>{title}</title>
        <meta name="description" content={description} />
        {richSnippets && (
          <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(richSnippets) }} />
        )}
        <link rel="canonical" href={canURL} />
        <meta name="robots" content={metaRobots || 'index, follow'} />
      </>
    </Head>
  )
}
